<template>
    <div class="page-cnt w-full h-full bg-white flex flex-column align-items-center gap-6">
        <div class="header w-full">
            <span v-for="n in 3" :key="n" :class="`item-${n}`" />
        </div>

        <div class="w-full h-full flex flex-column align-items-center gap-6 px-4 md:px-7">
            <Image src="layout/images/duon_logo_azul.svg" alt="Logo formando a palavra Duon" width="132" class="logo" />

            <div class="flex flex-column align-items-center text-bluegray-900 agendamentos-cnt w-full h-full">
                <h1 class="font-semibold title text-2xl text-center md:text-5xl">Bem-vindo a confirmação de consulta</h1>
                <h5 class="font-normal subtitle text-lg text-center md:text-2xl">Abaixo os agendamentos disponívels para sua região</h5>

                <div class="relative w-full flex flex-column lg:flex-row md:align-items-center">
                    <div class="md:hidden w-full mt-4 flex justify-content-center">
                        <Button
                            aria-label="Atualizar agendamentos"
                            label="Atualizar agendamentos"
                            class="p-button-rounded p-button-lg w-full mx-6"
                            style="background-color: #3f51b5; border: #3f51b5"
                            icon="pi pi-sync"
                            icon-class="text-sm mt-1"
                            @click="atualizarAgendamentos"
                            :loading="loading"
                        />
                    </div>
                    <div class="relative w-full flex align-items-center">
                        <CustomTabMenu
                            :items="items"
                            :loading="loading"
                            :quantidade-confirmados="quantidadeConfirmados"
                            @alterou-aba="atualizarAgendamentos"
                        />
                        <Button
                            aria-label="Atualizar agendamentos"
                            label="Atualizar"
                            class="hidden md:block lg:hidden p-button-rounded p-button-lg absolute right-0"
                            style="background-color: #3f51b5; border: #3f51b5"
                            icon="pi pi-sync"
                            icon-class="text-sm mt-1"
                            @click="atualizarAgendamentos"
                            :loading="loading"
                        />
                    </div>
                    <Button
                        label="Atualizar agendamentos"
                        class="hidden p-button-rounded p-button-lg lg:flex absolute right-0 z-5"
                        style="padding: 0.7rem 1.4rem; background-color: #3f51b5; border: #3f51b5; font-family: Poppins-Medium, sans-serif"
                        icon="pi pi-sync"
                        icon-class="text-sm mr-2 mt-1"
                        @click="atualizarAgendamentos"
                        :loading="loading"
                    />
                </div>

                <template v-if="!loading">
                    <router-view v-if="+itemRotaAtual.quantity" />
                    <EstadoVazio v-else :nomeRota="itemRotaAtual.label" class="border-round-2xl bg-bluegray-50 p-4 w-full" />
                </template>
                <div v-else class="border-round-2xl bg-bluegray-50 p-4 w-full flex flex-column gap-4">
                    <Skeleton width="100%" height="5rem" />
                    <Skeleton width="100%" height="5rem" />
                    <Skeleton width="100%" height="5rem" />
                    <Skeleton width="100%" height="5rem" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import { logoutPrestador } from '@/services/auth';
import CustomTabMenu from './components/CustomTabMenu.vue';
import EstadoVazio from './components/EstadoVazio.vue';
import { computed } from 'vue';

export default {
    components: {
        CustomTabMenu,
        EstadoVazio
    },
    data() {
        return {
            loading: false,
            items: [
                {
                    label: 'Pendentes',
                    to: '/confirmacao-prestador/agendamentos/pendentes',
                    quantity: 0,
                    fatorCorrecao: -1
                },
                {
                    label: 'Confirmados',
                    to: '/confirmacao-prestador/agendamentos/futuros',
                    quantity: 0,
                    fatorCorrecao: 1
                }
            ],
            quantidadeConfirmados: 0
        };
    },
    computed: {
        itemRotaAtual() {
            const rotaAtual = this.$route.fullPath;
            return this.items.find((item) => item.to === rotaAtual);
        }
    },
    methods: {
        async carregarQuantidadeAgendamentos() {
            this.loading = true;
            try {
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                const { data } = await getClientBase(authToken).get('/acesso-prestador/quantidade-agendamentos');
                this.items[0].quantity = data.quantidade_agendamentos_pendentes;
                this.items[1].quantity = data.quantidade_agendamentos_agendados;
                getClientBase(authToken).get('/acesso-prestador/agendamentos/atualizar-status-nao-acessado');
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
            this.loading = false;
        },
        async atualizarAgendamentos() {
            await this.carregarQuantidadeAgendamentos();
            this.quantidadeConfirmados = 0;
        },
        confirmar() {
            this.quantidadeConfirmados++;
        }
    },
    provide() {
        return {
            quantidadeConfirmados: computed(() => this.quantidadeConfirmados),
            confirmar: this.confirmar
        };
    },
    async mounted() {
        await this.carregarQuantidadeAgendamentos();
    }
};
</script>

<style lang="scss" scoped>
$rosa-claro: #3ba7e3;
$rosa-medio: #364de7;
$rosa-primario: #3f51b5;

@font-face {
    font-family: Poppins-Regular;
    src: url('../../../../public/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../../../../public/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../../../../public/fonts/poppins/Poppins-Medium.ttf');
}

.page-cnt {
    min-height: 100vh;
    font-family: Poppins-Regular, sans-serif;

    .header {
        height: 4px;
        display: grid;
        grid-template-columns: 1fr 2fr 3fr;

        .item-1 {
            background-color: $rosa-claro;
        }

        .item-2 {
            background-color: $rosa-medio;
        }

        .item-3 {
            background-color: $rosa-primario;
        }
    }

    .agendamentos-cnt {
        .title {
            font-family: Poppins-Medium, sans-serif;
            margin: 0;
        }

        .subtitle {
            margin: 9px 0 0;
        }
    }
}
</style>
