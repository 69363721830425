<template>
    <div class="w-full flex justify-content-center align-items-center text-bluegray-700 md:h-30rem" style="font-family: Poppins-Medium, sans-serif">
        <div class="flex flex-column align-items-center">
            <Image class="flex justify-content-center" src="/layout/images/acesso-prestador/ilustracao-estado-vazio.svg" alt="Calendário dormindo" />
            <div class="text-2xl md:text-3xl text-center md:w-7 m-0 md:m-auto">Nenhum {{ nomeTransformado }} disponível na sua região no momento</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        nomeRota: { type: String, required: true }
    },
    computed: {
        nomeTransformado() {
            let nomeMinusculo = this.nomeRota.toLowerCase();
            nomeMinusculo = 'agendamento ' + nomeMinusculo;
            return nomeMinusculo
                .split(' ')
                .map((palavra) => palavra.replace(/s$/, ''))
                .join(' ');
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Medium;
    src: url('../../../../../public/fonts/poppins/Poppins-Medium.ttf');
}
</style>
