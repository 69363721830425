<template>
    <div class="w-full flex justify-content-center align-items-center text-bluegray-700 my-4">
        <div
            v-for="item of items"
            :key="item"
            class="flex align-items-center gap-2 md:gap-3 px-4 py-2 border-bottom-1 cursor-pointer"
            :class="item.to === $route.fullPath ? 'rosa-primario' : 'border-bluegray-100 hover:border-bluegray-800 hover:bg-bluegray-50'"
            @click="alterarAba(item)"
            style="font-family: Poppins-Regular, sans-serif"
        >
            <span class="font-bold text-center md:text-left">{{ item.label }}</span>
            <span
                class="border-1 border-bluegray-100 border-round flex align-items-center justify-content-center font-bold text-bluegray-800"
                :class="{ 'fundo-rosa-primario': quantidadeConfirmados && item.fatorCorrecao === 1 }"
                style="width: 25px; height: 25px"
            >
                <template v-if="!loading">
                    {{ ajustarQuantidade(item) }}
                </template>
                <i v-else class="pi pi-spin pi-spinner" style="font-size: 1rem" />
            </span>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['alterouAba'],
    props: {
        items: { type: Array, required: true },
        loading: { type: Boolean, default: false },
        quantidadeConfirmados: Number
    },
    methods: {
        ajustarQuantidade(item) {
            if (this.quantidadeConfirmados && item.fatorCorrecao) {
                return +item.quantity + item.fatorCorrecao * this.quantidadeConfirmados;
            }
            return +item.quantity;
        },
        alterarAba(item) {
            this.$router.push(item.to);
            this.$emit('alterouAba');
        }
    }
};
</script>

<style lang="scss" scoped>
$rosa-primario: #3f51b5;

.rosa-primario {
    color: $rosa-primario;
    border-bottom: 1px solid $rosa-primario;
}

.fundo-rosa-primario {
    background-color: $rosa-primario !important;
    color: white !important;
    border: none !important;
}
</style>
